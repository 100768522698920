import Typed from "../vendor/typed.umd.js";
import { CountUp } from "../vendor/countUp.min.js";

//print Text Banner
const printText = document.querySelector('.banner-main__print');
if (printText) {
  var typed = new Typed('.banner-main__print', {
    strings: ['создаём', 'продвигаем'],
    typeSpeed: 200,
    backSpeed: 100,
    backDelay: 180,
    startDelay: 1000,
    loop: true,
  });
}

// count adventures number
const adventuresList = document.querySelector('.js-adventures__list');
if (adventuresList) {
  const listItems = adventuresList.querySelectorAll('.adventures__item-number');
  const listItemsDown = adventuresList.querySelectorAll('.adventures__item-number__down');

  const options_1 = {
    decimalPlaces: '',
    duration: 3,
    separator: '',
    enableScrollSpy: true,
    scrollSpyOnce: true,
  };

  const options_2 = {
    decimalPlaces: 1,
    duration: 3,
    separator: '',
    decimal: ',',
    enableScrollSpy: true,
    scrollSpyOnce: true,
  };

  listItems.forEach((item) => {

    let number = +item.innerHTML;

    if (Number.isInteger(number)) {
      let countUp = new CountUp(item, number, options_1);
      if (!countUp.error) {
        countUp.start();
      } else {
        console.log(countUp.error);
      }
      countUp.handleScroll();
    } else {
      let countUp = new CountUp(item, number, options_2);
      if (!countUp.error) {
        countUp.start();
      } else {
        console.log(countUp.error);
      }
      countUp.handleScroll();
    }
    // animate top
    if (listItemsDown) {
      countDown(listItemsDown)
    }
  });
}

function countDown(items) {
  items.forEach(item => {
    const changeTime = +item.getAttribute('time');
    let n = +item.textContent;
    const max = +item.getAttribute('max');
    const interval = setInterval(function () {
      if (max < n) {
        n--;
        item.textContent = n;
        if (n <= max) {
          clearInterval(interval)
        }
      }

    }, Math.ceil(changeTime / (max * 5)));
  })
}


