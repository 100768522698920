import JustValidate from 'just-validate';
import Inputmask from "inputmask";
import vars from '../_vars';

export const validateForms = () => {
  let imageUpload = document.querySelector('#myfile');
  let fileLoad = document.querySelector('.file-title');

  function fileChanged() {
    const file = imageUpload.files[0];
    if (!file) {
      return;
    }
    let nameFile = file.name;
    // проверка формата файла
    if (file.name) {
      fileLoad.classList.add('load')
      fileLoad.querySelector('span').textContent = nameFile;
      const reader = new FileReader();
    }
  }
  if (imageUpload) {
    imageUpload.addEventListener('change', fileChanged);
  }

  const form = document.querySelector('.form');
  if (form) {
    const contactsFormHeader = document.querySelector('.form-contacts-header');
    const contactsForm = document.querySelector('.form-contacts_bottom');
    const supliersForm = document.querySelector('.form-suppliers');
    const telSelectorS = document.querySelectorAll('input[type="tel"]');
    // inputmask
    if (telSelectorS) {
      telSelectorS.forEach(item => {
        const inputMask = new Inputmask('+7 (999) 999-99-99');
        inputMask.mask(item);
      })
    }

    // форма во всплывающем окне
    if (contactsFormHeader) {
      const btnContactsHeader = contactsFormHeader.querySelector('.btn-submit');
      let inputCheckHeader = contactsFormHeader.querySelector("#styled-checkbox-header");
      // let telSelectorHeader = contactsFormHeader.querySelector('input[type="tel"]');
      // проверка соглашения
      function stateCalcHeader() {
        if (inputCheckHeader.checked) {
          btnContactsHeader.disabled = false;
        } else {
          btnContactsHeader.disabled = true;
        }
      }
      inputCheckHeader.addEventListener("change", stateCalcHeader)

      let validationContactsHeader = new JustValidate('.form-contacts-header');

      validationContactsHeader
        .addField('.input-name', [
          {
            rule: 'minLength',
            value: 3,
            errorMessage: 'Введите не менее 3 символов',
          },
          {
            rule: 'maxLength',
            value: 30,
            errorMessage: 'Превышает допустимое значение',
          },
          {
            rule: 'required',
            value: true,
            errorMessage: 'Введите имя - доступно написание буквами'
          }
        ])
        .addField('.input-tel', [
          {
            rule: 'function',
            validator: function () {
              const phoneHeader = contactsFormHeader.querySelector('input[type="tel"]').inputmask.unmaskedvalue();
              return phoneHeader.length === 10;
            },
            errorMessage: "Введите корректные данные",
          },
        ])
        .addField('.input-mail', [
          {
            rule: 'required',
            value: true,
            errorMessage: "Поле обязательно для заполнения",
          },
          {
            rule: 'email',
            value: true,
            errorMessage: "Введите корректные данные",
          },
        ])
        .addField('.input-check', [
          {
            rule: 'required',
            validator: function () {
              if (inputCheckHeader.checked) {
                btnContactsHeader.disabled = false;
              } else {
                btnContactsHeader.disabled = true;
              }
            },
            errorMessage: "Необходимо ваше согласие",
          }
        ]).onSuccess((event) => {
          // console.log('Проверка проходит и форма отправлена', event);
          closeModal();
          document.querySelector('.modal-overlay').classList.remove('modal-overlay--visible');
          vars.modals.forEach((el) => {
            el.classList.remove('modal--visible');
          });
          // openModal(vars.modalSuccess);

          let formData = new FormData(event.target);
          formData.append('ajx', 'y');
          console.log(...formData);
          let xhr = new XMLHttpRequest();

          xhr.onreadystatechange = function () {

            if (xhr.readyState === 4) {
              let response = JSON.parse(xhr.responseText);
              if (xhr.status === 200 && response.success) {
                console.log(xhr.response);
                openModal(vars.modalSuccess);
              } else{
                openModal(vars.modalError);
              }
            }
          }

          xhr.open('POST', form.action, true);
          xhr.send(formData);

          event.target.reset();
        });
    }

    // форма на странице контактов
    if (contactsForm) {
      const btnFormContacts = contactsForm.querySelector('.btn-submit');
      let inputCheckContacts = contactsForm.querySelector("#styled-checkbox");
      // проверка соглашения
      function stateCalcContacts() {
        if (inputCheckContacts.checked) {
          btnFormContacts.disabled = false;
        } else {
          btnFormContacts.disabled = true;
        }
      }
      inputCheckContacts.addEventListener("change", stateCalcContacts)

      let validationContacts = new JustValidate('.form-contacts');

      validationContacts
        .addField('.input-name', [
          {
            rule: 'minLength',
            value: 3,
            errorMessage: 'Введите не менее 3 символов',
          },
          {
            rule: 'maxLength',
            value: 30,
            errorMessage: 'Превышает допустимое значение',
          },
          {
            rule: 'required',
            value: true,
            errorMessage: 'Введите имя - доступно написание буквами'
          }
        ])
        .addField('.input-tel', [
          {
            rule: 'function',
            validator: function () {
              const phone = contactsForm.querySelector('input[type="tel"]').inputmask.unmaskedvalue();
              return phone.length === 10;
            },
            errorMessage: "Введите корректные данные",
          },
        ])
        .addField('.input-mail', [
          {
            rule: 'required',
            value: true,
            errorMessage: "Поле обязательно для заполнения",
          },
          {
            rule: 'email',
            value: true,
            errorMessage: "Введите корректные данные",
          },
        ])
        .addField('.input-check', [
          {
            rule: 'required',
            validator: function () {
              if (inputCheckContacts.checked) {
                btnFormContacts.disabled = false;
              } else {
                btnFormContacts.disabled = true;
              }
            },
            errorMessage: "Необходимо ваше согласие",
          }
        ]).onSuccess((event) => {
          // console.log('Проверка проходит и форма отправлена', event);
          // если проверка прошла показать popup
          closeModal();
          document.querySelector('.modal-overlay').classList.remove('modal-overlay--visible');
          vars.modals.forEach((el) => {
            el.classList.remove('modal--visible');
          });
          // если проверка прошла показать popup
          // openModal(vars.modalSuccess);

          let formData = new FormData(event.target);
          formData.append('ajx', 'y');
          console.log(...formData);
          let xhr = new XMLHttpRequest();

          xhr.onreadystatechange = function () {

            if (xhr.readyState === 4) {
              let response = JSON.parse(xhr.responseText);
              if (xhr.status === 200 && response.success) {
                console.log(xhr.response);
                openModal(vars.modalSuccess);
              } else{
                openModal(vars.modalError);
              }
            }
          }

          xhr.open('POST', form.action, true);
          xhr.send(formData);

          event.target.reset();
        });
    }

    if (supliersForm) {
      const btnFormsupliers = supliersForm.querySelector('.btn-submit');
      btnFormsupliers.disable = true;
      let inputCheck = supliersForm.querySelector("#styled-checkbox-suppliers");
      // проверка соглашения
      function stateCalcSupliers() {
        if (inputCheck.checked) {
          btnFormsupliers.disabled = false;
        } else {
          btnFormsupliers.disabled = true;
        }
      }
      inputCheck.addEventListener("change", stateCalcSupliers)

      const validationSuppliers = new JustValidate('.form-suppliers');

      validationSuppliers
        .addField('.input-name', [
          {
            rule: 'minLength',
            value: 3,
            errorMessage: 'Введите не менее 3 символов',
          },
          {
            rule: 'maxLength',
            value: 30,
            errorMessage: 'Превышает допустимое значение',
          },
          {
            rule: 'required',
            value: true,
            errorMessage: 'Введите имя - доступно написание буквами'
          }
        ])
        .addField('.input-tel', [
          {
            rule: 'function',
            validator: function () {
              let phone = supliersForm.querySelector('input[type="tel"]').inputmask.unmaskedvalue();
              return phone.length === 10;
            },
            errorMessage: "Введите корректные данные",
          },
        ])
        .addField('.input-text', [
          {
            rule: 'required',
            value: true,
            errorMessage: "Это обязательное поле!",
          },
        ])
        .addField('#myfile', [
          {
            rule: 'minFilesCount',
            value: 1,
            errorMessage: "Загрузите файл",
          },
          {
            rule: 'maxFilesCount',
            value: 1,
            errorMessage: "Загрузите файл",
          },
        ])
        .addField('.input-check', [
          {
            rule: 'required',
            validator: function () {
              if (inputCheck.checked) {
                btnFormsupliers.disabled = false;
              } else {
                btnFormsupliers.disabled = true;
              }
            },
            errorMessage: "Необходимо ваше согласие",
          }
        ]).onSuccess((event) => {
          // console.log('Проверка проходит и форма отправлена', event);
          // если проверка прошла показать popup
          closeModal();
          document.querySelector('.modal-overlay').classList.remove('modal-overlay--visible');
          vars.modals.forEach((el) => {
            el.classList.remove('modal--visible');
          });
          // если проверка прошла показать popup

          let imageUpload = document.querySelector('#myfile');
          let fileLoad = document.querySelector('.file-title');



          let formData = new FormData(event.target);
          formData.append('ajx', 'y');
          console.log(...formData);
          let xhr = new XMLHttpRequest();

          xhr.onreadystatechange = function () {

            if (xhr.readyState === 4) {
              let response = JSON.parse(xhr.responseText);
              if (xhr.status === 200 && response.success) {
                if(imageUpload) {
                  imageUpload.value = '';
                  // imageUpload.reset();
                  fileLoad.classList.remove('load')
                  fileLoad.querySelector('span').textContent = 'Резюме';
                }
                console.log(xhr.response);
                openModal(vars.modalSuccess);
              } else{
                openModal(vars.modalError);
              }
            }
          }

          xhr.open('POST', form.action, true);
          xhr.send(formData);
          event.target.reset();
        });
    }
  }

};


// const modalPopup = document.querySelector('.modal');
function openModal(modal) {
  if (modal) {
    modal.classList.add('show');
    vars.overlayDefault.classList.add('overlay-visible');
  } else {
    vars.modalPopup.classList.add('show');
  }

  vars.bodyEl.classList.add('stop-scroll');
}
// проверка открыт ли попап
function examination() {
  if (vars.modalPopup) {
    if (vars.modalPopup.classList.contains('show')) {
      vars.bodyEl.classList.add('stop-scroll');
      vars.overlayDefault.classList.add('overlay-visible');
    }
  }
}

// examination();

vars.overlayDefault.addEventListener('click', (e) => {
  // console.log(e.target);

  if (e.target == vars.overlayDefault) {
    vars.overlayDefault.classList.remove('overlay-visible');
    if (vars.modals) {
      vars.modals.forEach((el) => {
        el.classList.remove('modal--visible');
      });
    }

    if (document.querySelector(".modal-success").classList.contains('show')) {
      document.querySelector(".modal-success").classList.remove('show');
    }

    if (document.querySelector(".modal-error").classList.contains('show')) {
      document.querySelector(".modal-error").classList.remove('show');
    }

    vars.bodyEl.classList.remove('stop-scroll');
  }
});

// const modals = document.querySelectorAll(".modal");
if (vars.modals.length > 0) {
  vars.modals.forEach(modal => {
    modal.addEventListener("click", (event) => {
      const target = event.target;
      if (!target.closest(".modal-content") && !target.classList.contains("modal-content")) {
        closeModal();
        vars.overlayDefault.classList.remove('overlay-visible');
      }
    })
  })
}
