const accordionsList = document.querySelectorAll('.accordion-li');

if (accordionsList) {
  accordionsList.forEach(el => {
    el.addEventListener('click', (e) => {
      const self = e.currentTarget;
      const control = self.querySelector('.accordion__control');
      const content = self.querySelector('.accordion__content');

      self.classList.toggle('open');

      // if open accordion
      if (self.classList.contains('open')) {
        control.setAttribute('aria-expanded', true);
        content.setAttribute('aria-hidden', false);
        content.style.maxHeight = `inherit`;
      } else {
        control.setAttribute('aria-expanded', false);
        content.setAttribute('aria-hidden', true);
        content.style.maxHeight = null;
      }
    });
  });
}

// accordion
const btnsAcc = document.querySelectorAll(".acc-btn");
// event
if (btnsAcc) {
  function accordion() {
    // this = the btn | icon & bg changed
    this.classList.toggle("is-open");
    // the acc-content
    const content = this.nextElementSibling;
    console.log(content.style.maxHeight)
    if (content.style.maxHeight) content.style.maxHeight = null;
    else content.style.maxHeight = `inherit`;
  }
  btnsAcc.forEach((el) => {
    el.addEventListener("click", accordion);
    el.addEventListener("resize", accordion)
  });
  // accordion Sub
  const accordionItemHeaders = document.querySelectorAll(".accord-item-header");
  if (accordionItemHeaders) {
    accordionItemHeaders.forEach(accordionItemHeader => {
      accordionItemHeader.addEventListener("click", event => {

        accordionItemHeader.classList.toggle("active");
        const accordionItemBody = accordionItemHeader.nextElementSibling;
        if (accordionItemHeader.classList.contains("active")) {

          accordionItemBody.style.maxHeight = `inherit`;
          console.log(accordionItemBody);
        }
        else {
          accordionItemBody.style.maxHeight = 0;
        }
      });
    });
  }
}


