// Данный файл - лишь собрание подключений готовых компонентов.
// Рекомендуется создавать отдельный файл в папке components и подключать все там

// Определение операционной системы на мобильных
import { mobileCheck } from "./functions/mobile-check";
console.log(mobileCheck())


// Определение ширины экрана
// import { isMobile, isTablet, isDesktop } from './functions/check-viewport';
// if (isDesktop()) {
//   console.log('...')
// }

// Троттлинг функции (для ресайза, ввода в инпут, скролла и т.д.)
// import { throttle } from './functions/throttle';
// let yourFunc = () => { console.log('throttle') };
// let func = throttle(yourFunc);
// window.addEventListener('resize', func);

// Фикс фулскрин-блоков
// import './functions/fix-fullheight';

// Реализация бургер-меню
import { burger } from './functions/burger';

// эффект печати
import "./functions/printtext";

// Реализация остановки скролла (не забудьте вызвать функцию)
// import { disableScroll } from './functions/disable-scroll';

// Реализация включения скролла (не забудьте вызвать функцию)
// import { enableScroll } from './functions/enable-scroll';

// Реализация модального окна
// import GraphModal from 'graph-modal';
// const modal = new GraphModal();

// Реализация табов
// import GraphTabs from 'graph-tabs';
// const tabs = new GraphTabs('tab');

// Получение высоты шапки сайта (не забудьте вызвать функцию)
// import { getHeaderHeight } from './functions/header-height';

// Подключение плагина кастом-скролла
// import 'simplebar';

// Подключение плагина для позиционирования тултипов
// import { createPopper, right} from '@popperjs/core';
// createPopper(el, tooltip, {
//   placement: 'right'
// });

// Подключение свайпера
import Swiper from './vendor/swiper-bundle.min.js';

const topBrandsSwiper = document.querySelector('.topBrandsSwiper');
const bottomBrandsSwiper = document.querySelector('.bottomBrandsSwiper');
const casesSwiper = document.querySelector('.cases-swiper');
const howWorkSwiper_1 = document.querySelector('.how-with-us-swiper');
const howWorkSwiper_2 = document.querySelector('.with-us-swiper');

function initSlider(slider, options) {
  if (!slider.classList.contains('swiper-container-initialized') && !slider.classList.contains('swiper-initialized')) {
    new Swiper(slider, options)
  }
}

if (topBrandsSwiper) {
  initSlider(topBrandsSwiper, {
    slidesPerView: 'auto',
    spaceBetween: 20,
    loop: true,
    centeredSlides: true,
    speed: 2000,
    autoplay: {
      delay: 0,
    },
    waitForTransition: false,
    breakpoints: {
      320: {
        spaceBetween: 20,
      },
      578: {
        spaceBetween: 30,
      },
    },
  })
}
if (bottomBrandsSwiper) {
  initSlider(bottomBrandsSwiper, {
    slidesPerView: 'auto',
    reverseDirection: true,
    spaceBetween: 20,
    loop: true,
    speed: 2000,
    autoplay: {
      delay: 0,
    },
    waitForTransition: false,
    breakpoints: {
      320: {
        spaceBetween: 20,
      },
      578: {
        spaceBetween: 30,
      },
    },
  })
}

if (casesSwiper) {
  let swiperCasesSwiper = new Swiper(casesSwiper, {
    slidesPerView: 1.17,
    spaceBetween: 20,
    watchOverflow: true,
    watchSlidesProgress: true,
    disableOnInteraction: false,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    autoplay: true,
    breakpoints: {
      320: {
        slidesPerView: 1.17,
        spaceBetween: 20,
      },
      578: {
        slidesPerView: 1.52,
        spaceBetween: 28,
      },
      780: {
        slidesPerView: 2,
        spaceBetween: 28,
      },
    },

  })
  casesSwiper.addEventListener("mouseenter", event => {
    swiperCasesSwiper.autoplay.stop();
  });

  casesSwiper.addEventListener("mouseleave", event => {
    swiperCasesSwiper.autoplay.start();
  });
}

if (howWorkSwiper_1) {
  let howWork_Swiper1 = new Swiper(howWorkSwiper_1, {
    autoHeight: true,
    slidesPerView: 1.17,
    spaceBetween: 20,
    watchOverflow: true,
    watchSlidesProgress: true,
    disableOnInteraction: false,
    autoplay: {
      delay: 3000,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1.17,
        spaceBetween: 20,
      },
      578: {
        slidesPerView: 1.52,
        spaceBetween: 30,
      },
      780: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  })
  howWorkSwiper_1.addEventListener("mouseenter", event => {
    howWork_Swiper1.autoplay.stop();
  });

  howWorkSwiper_1.addEventListener("mouseleave", event => {
    howWork_Swiper1.autoplay.start();
  });
}
if (howWorkSwiper_2) {
  let howWork_Swiper2 = new Swiper(howWorkSwiper_2, {
    autoHeight: true,
    slidesPerView: 1.17,
    spaceBetween: 20,
    watchOverflow: true,
    watchSlidesProgress: true,
    disableOnInteraction: false,
    autoplay: {
      delay: 3000,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1.17,
        spaceBetween: 20,
      },
      578: {
        slidesPerView: 1.75,
        spaceBetween: 30,
      },
      780: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  })

  howWorkSwiper_2.addEventListener("mouseenter", event => {
    howWork_Swiper2.autoplay.stop();
  });

  howWorkSwiper_2.addEventListener("mouseleave", event => {
    howWork_Swiper2.autoplay.start();
  });
}

// появление контента на странице
const animItems = document.querySelectorAll('._anim-items');

if (animItems.length > 0) {
  window.addEventListener('scroll', animOnScroll);

  function animOnScroll() {
    for (let index = 0; index < animItems.length; index++) {
      const animItem = animItems[index];
      const animItemHeight = animItem.offsetHeight;
      const animItemOffset = offset(animItem).top;
      const animStart = 6;

      let animItemPoint = window.innerHeight - animItemHeight / animStart;
      if (animItemHeight > window.innerHeight) {
        animItemPoint = window.innerHeight - window.innerHeight / animStart;
      }

      if ((pageYOffset > animItemOffset - animItemPoint) && pageYOffset < (animItemOffset + animItemHeight)) {
        animItem.classList.add('_active');
      } else {
        if (!animItem.classList.contains('_anim-no-hide')) {
          animItem.classList.remove('_active');
        }
      }
    }
  }

  function offset(el) {
    const rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
      top: rect.top + scrollTop,
      left: rect.left + scrollLeft
    }
  }

  setTimeout(() => {
    animOnScroll();
  }, 300);
}


import './functions/accordion';

import { validateForms } from './functions/validate-forms';
validateForms('.form-contacts');

const btnsModal = document.querySelectorAll('.modal-btn');
const modalOverlay = document.querySelector('.modal-overlay');
const modals = document.querySelectorAll('.modal');

if (btnsModal) {
  btnsModal.forEach((el) => {
    el.addEventListener('click', (e) => {
      let path = e.currentTarget.getAttribute('data-path');

      modals.forEach((el) => {
        el.classList.remove('modal--visible');
      });

      document.querySelector(`[data-target="${path}"]`)?.classList.add('modal--visible');
      modalOverlay?.classList.add('modal-overlay--visible');
    });
  });

  modalOverlay?.addEventListener('click', (e) => {
    if (e.target == modalOverlay) {
      modalOverlay.classList.remove('modal-overlay--visible');
      modals.forEach((el) => {
        el.classList.remove('modal--visible');
      });
    }
  });
}


import './functions/tabs';
import './functions/select';
