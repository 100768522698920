export default {
  windowEl: window,
  documentEl: document,
  htmlEl: document.documentElement,
  bodyEl: document.body,
  modalPopup: document.querySelector('.modal'),
  modals: document.querySelectorAll(".modal"),
  modalSuccess: document.querySelector(".modal-success"),
  modalError: document.querySelector(".modal-error"),
  overlayDefault: document.querySelector('.overlay'),
}
