const tabs = document.querySelector('.tabs');
const tabsBtn = document.querySelectorAll('.tabs__btn');
const tabsContent = document.querySelectorAll('.tabs__content');

if (tabs) {
  const tabsNext = tabs.querySelector('.tabs__arrow--next');
  tabs.addEventListener('click', (e) => {
    if (e.target.classList.contains('tabs__btn')) {

      const tabsPath = e.target.dataset.tabsPath;
      tabsBtn.forEach(el => { el.classList.remove('tabs__btn--active') });

      document.querySelector(`[data-tabs-path="${tabsPath}"]`).classList.add('tabs__btn--active');
      let activeBtn = document.querySelector(`[data-tabs-path="${tabsPath}"]`);
      let activeParent = activeBtn.closest('.tabs__item');
      let nextParent = activeParent.nextElementSibling;

      if (nextParent) {
        let nextPath = nextParent.querySelector('.tabs__btn').dataset.tabsPath;
        tabsNext.classList.remove('last');
        tabsNext.querySelector('span').innerHTML = `${nextPath}`;
      } else {
        tabsNext.classList.add('last')
        tabsNext.querySelector('span').innerHTML = 'Продолжение следует...';
      }

      tabsHandler(tabsPath);
    }

    if (e.target.classList.contains('tabs__arrow--next')) {
      let activeBtn = document.querySelector('.tabs__btn--active');
      let activeParent = activeBtn.closest('.tabs__item');
      let nextParent = activeParent.nextElementSibling;

      if (nextParent) {
        let nextActive = nextParent.querySelector('.tabs__btn');
        tabsBtn.forEach(el => { el.classList.remove('tabs__btn--active') });
        nextActive.classList.add('tabs__btn--active');
        tabsNext.classList.remove('last');
        let path = nextActive.dataset.tabsPath;
        let nextNext_Parent = activeParent.nextElementSibling.nextElementSibling;
        tabsHandler(path);
        if(!nextNext_Parent) {
          tabsNext.classList.add('last')
          tabsNext.querySelector('span').innerHTML = 'Продолжение следует...';
        } else {
          tabsNext.querySelector('span').innerHTML = `${nextNext_Parent.querySelector('.tabs__btn').dataset.tabsPath}`;
        }

      } else {
        tabsNext.classList.add('last')
        tabsNext.querySelector('span').innerHTML = 'Продолжение следует...';
      }
    }
  });
}

const tabsHandler = (path) => {
  tabsContent.forEach(el => { el.classList.remove('tabs__content--active') });
  document.querySelector(`[data-tabs-target="${path}"]`).classList.add('tabs__content--active');
};
