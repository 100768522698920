import vars from '../_vars';
const burger = document.querySelector('[data-burger]');
const overlayMenu = document.querySelector('.overlay');
const nav = document.querySelector('[data-nav]');
const navShadow = document.querySelector('.box-for-shadow');
const navItems = nav.querySelectorAll('a');
const body = document.body;
const header = document.querySelector('.header');
const inputs = document.querySelectorAll('input');
const accordions = document.querySelectorAll('.accordion');
const headerHeight = header.offsetHeight;
const reviewBtns = document.querySelectorAll('.review-art');
const modalOverlay = document.querySelector('.modal-overlay');
const modalPopup = document.querySelector('.modal');
let modals = document.querySelectorAll(".modal");
const modalSuccess = document.querySelector(".modal-success");
const modalError = document.querySelector(".modal-error");
const overlayDefault = document.querySelector('.overlay');

window.addEventListener("scroll", function () {
  fixedHeader();
});

fixedHeader();
function fixedHeader() {
  if (window.scrollY > 5) {
    header.classList.add("scrolled");
  } else {
    header.classList.remove("scrolled");
  }
};

document.querySelector(':root').style.setProperty('--header-height', `${headerHeight}px`);
// burder open/close
burger.addEventListener('click', (e) => {
  body.classList.toggle('stop-scroll');
  burger.classList.toggle('burger--active');
  overlayMenu.classList.toggle('transparent');
  nav.classList.toggle('nav--visible');
  if(!header.classList.contains("scrolled")) {
    header.classList.toggle("header-open")
  }
  if(burger.classList.contains('burger--active')) {
    navShadow.style.top = (header.scrollHeight + nav.scrollHeight) - navShadow.scrollHeight + 'px';
    navShadow.classList.add("visible")
  } else {
    navShadow.classList.remove("visible");
    overlayMenu.classList.remove('transparent');
  }

});

// click item menu -> no-scroll + close menu
navItems.forEach(el => {
  el.addEventListener('click', () => {
    body.classList.remove('stop-scroll');
    burger.classList.remove('burger--active');
    nav.classList.remove('nav--visible');
    navShadow.classList.remove("visible");
    if(!header.classList.contains("scrolled")) {
      header.classList.remove("header-open")
    }
    overlayMenu.classList.remove('overlay-visible','transparent');
  });
});

overlayMenu.addEventListener('click', (e) => {
  console.log(e.target);

  if (e.target == overlayMenu) {
    overlayMenu.classList.remove('overlay-visible','transparent');
    if(vars.modals) {
      vars.modals.forEach((el) => {
        el.classList.remove('modal--visible');
      });
    }

    if(document.querySelector(".modal-success").classList.contains('show')) {
      document.querySelector(".modal-success").classList.remove('show');
    }

    burger.classList.remove('burger--active');
    nav.classList.remove('nav--visible');
    header.classList.remove("header-open", "scrolled")
    navShadow.classList.remove("visible")
    document.body.classList.remove('stop-scroll');
  }
});

// const modalPopup = document.querySelector('.modal');
function openModal(modal) {
  if (modal) {
    modal.classList.add('show');
    overlayDefault.classList.add('overlay-visible');
  } else {
    modalPopup.classList.add('show');
  }

  body.classList.add('stop-scroll');
}
// проверка открыт ли попап
function examination() {
  if (modalPopup) {
    if (modalPopup.classList.contains('show')) {
      body.classList.add('stop-scroll');
      overlayDefault.classList.add('overlay-visible');
    }
  }
}

// const modals = document.querySelectorAll(".modal");
if (modals.length > 0) {
  modals.forEach(modal => {
    modal.addEventListener("click", (event) => {
      const target = event.target;
      if (!target.closest(".modal-content") && !target.classList.contains("modal-content")) {
        closeModal();
        overlayDefault.classList.remove('overlay-visible');
      }
    })
  })
}


// scroll into block class link-button
document.querySelectorAll('a.link-button[href^="#"').forEach(link => {
  link.addEventListener('click', function (e) {
    e.preventDefault();

    let href = this.getAttribute('href').substring(1);

    const scrollTarget = document.getElementById(href);
    const topOffsetHeader = document.querySelector('.header').offsetHeight;
    const elementPosition = scrollTarget.getBoundingClientRect().top;
    const offsetPosition = elementPosition - (topOffsetHeader + 20);

    window.scrollBy({
      top: offsetPosition,
      behavior: 'smooth'
    });
  });
});
